import Rails from "@rails/ujs"

window.searchUserStoriesRequestOnType = debounce(function() {
    var select_filter = document.getElementById('status-filter')
    var search_query = document.getElementById('search-field')
    var estimated = document.getElementById('estimated').checked
    var unestimated = document.getElementById('unestimated').checked
    var participates = document.getElementById('participates').checked
    Rails.ajax({
        url: "/user_stories",
        type: "get",
        data: "search_query=" + search_query.value + '&status_filter=' + select_filter.options[select_filter.selectedIndex].value + '&estimated=' + estimated + '&unestimated=' + unestimated + '&participates=' + participates,
        success: function(data) {},
        error: function(data) {}
    })
}, 500)

window.searchRoomsOnType = debounce(function() {
        var is_admin = document.getElementById('is-admin').checked
        var is_not_admin = document.getElementById('is-not-admin').checked
        var is_participating = document.getElementById('is-participating').checked
        var is_not_participating = document.getElementById('is-not-participating').checked
        Rails.ajax({
            url: "/rooms",
            type: "get",
            data: "is_admin=" + is_admin + '&is_not_admin=' + is_not_admin + '&is_participating=' + is_participating + '&is_not_participating=' + is_not_participating,
            success: function(data) {},
            error: function(data) {}
        })
    },
    750)

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this,
            args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


window.setEstimatedFalse = function() {
    document.getElementById('estimated').checked = false
}

window.setUnestimatedFalse = function() {
    document.getElementById('unestimated').checked = false
}